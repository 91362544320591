body{
  zoom: 85%;
}

@media screen and (max-width: 600px) {
  body{
    zoom: 100%;
  }
}
  

#projects{
  zoom: 110%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}