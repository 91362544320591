/* Scrollbar Track */
::-webkit-scrollbar {
    width: 12px; /* Width of the entire scrollbar */
  }
  
  /* Scrollbar Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar handle */
    border-radius: 6px; /* Rounded corners for the scrollbar handle */
  }
  
  /* Scrollbar Handle on Hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar handle on hover */
  }
  
  /* Scrollbar Track on Hover */
  ::-webkit-scrollbar-track:hover {
    background: #f1f1f1; /* Color of the scrollbar track on hover */
  }

